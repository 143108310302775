<template lang="pug">
.expense-view.mt-6( v-if="!isPending" )
  div( class="w-full px-8 xl:px-0 xl:w-8/12 2xl:w-1/2 mx-auto" )
    .d-flex.justify-between.align-center( v-if="!isPending" )
      v-breadcrumbs(
        class="ml-0 pl-0"
        :items="breadcrumbs"
      )
      ExpenseChip.mr-1( :expense="expense" )

    v-tabs.shadow.rounded-tr-lg.rounded-tl-lg.overflow-hidden( grow v-model="tab" )
      v-tab Expense
      v-tab( v-if="!isExpenseHistoriesPending" ) History ({{ expenseHistories.length }})

      v-tabs-items( v-model="tab" )
        v-tab-item.pa-8.border-t
          v-chip.rounded.w-full.mb-8(
            color="red"
            outlined
            v-if="expense.status === 'rejected' && expense.rejectNote"
          )
            v-icon.ml-1( small color="red" left) mdi-comment
            span.text-red-800 {{ expense.rejectNote }}

          h5( v-if="expense.orgType" ) Identity
          v-divider.mb-5( v-if="expense.orgType" )

          dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
            div(class='sm:col-span-1' v-if="expense.orgType" )
              dt.text-sm.font-medium.text-gray-500 Org Type
              dd.mt-1.text-sm.text-gray-900 {{ expense.orgType.name }}

            div(class='sm:col-span-1' v-if="expense.candidate" )
              dt.text-sm.font-medium.text-gray-500 Candidate
              dd.mt-1.text-sm.text-gray-900 {{ expense.candidate.fullName }}

            div(class='sm:col-span-1' v-if="expense.advocacy" )
              dt.text-sm.font-medium.text-gray-500 Advocacy
              dd.mt-1.text-sm.text-gray-900 {{ expense.advocacy.name }}

          h5.mt-8 Invoice
          v-divider.mb-5

          dl(class='sm:grid-cols-4 grid grid-cols-1 gap-x-4 gap-y-8')
            div(class='sm:col-span-2')
              dt.text-sm.font-medium.text-gray-500 Invoice Date
              dd.mt-1.text-sm.text-gray-900 {{ $day(expense.invoiceDate).format(formats.DATE) }}

            div(class='sm:col-span-2')
              dt.text-sm.font-medium.text-gray-500 Invoice Number
              dd.mt-1.text-sm.text-gray-900 {{ expense.invoiceNumber }}

            div(class='sm:col-span-2')
              dt.text-sm.font-medium.text-gray-500 Amount
              dd.mt-1.text-sm.text-gray-900 {{ expense.amount | toCurrency(2) }}

            div( v-if="expense.isDirectContribution" class='sm:col-span-2' )
              dt.text-sm.font-medium.text-gray-500 Type
              dd.mt-1.text-sm.text-gray-900 Direct Contribution

          h5.mt-8 Notes
          v-divider.mb-5

          div
            .text-sm {{ expense.notes }}

          template( v-if="!expense.isDirectContribution" )
            h5.mt-8 Vendor
            v-divider.mb-5

            dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8')
              div(class='sm:col-span-1')
                dt.text-sm.font-medium.text-gray-500 Vendor
                dd.mt-1.text-sm.text-gray-900 {{ expense.vendor.name }}

              div(class='sm:col-span-1')
                dt.text-sm.font-medium.text-gray-500 Type
                dd.mt-1.text-sm.text-gray-900 {{ expense.type }}

          template( v-if="expense.candidate" )
            h5.mt-8 Campaign
            v-divider.mb-5

            dl(class='sm:grid-cols-4 grid grid-cols-1 gap-x-4 gap-y-8')
              div(class='sm:col-span-2')
                dt.text-sm.font-medium.text-gray-500 Chamber
                dd.mt-1.text-sm.text-gray-900 {{ expense.campaign.formattedChamber }}

              div(class='sm:col-span-2')
                dt.text-sm.font-medium.text-gray-500 District
                dd.mt-1.text-sm.text-gray-900 {{ expense.campaign.districtNumber }}

              div(class='sm:col-span-2')
                dt.text-sm.font-medium.text-gray-500 Party
                dd.mt-1.text-sm.text-gray-900 {{ expense.campaign.party | capitalize }}

              div( class='sm:col-span-2' )
                dt.text-sm.font-medium.text-gray-500 Support Level
                dd.mt-1.text-sm.text-gray-900 {{ expense.campaign.supportLevel | capitalize }}

          h5.mt-8 Reach
          v-divider.mb-5

          dl(class='sm:grid-cols-2 grid grid-cols-1 gap-x-4 gap-y-8 mb-5')
            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Run Dates
              dd.mt-1.text-sm.text-gray-900
                | {{ $day(expense.runStartDate).format(formats.DATE) }} -
                | {{ $day(expense.runEndDate).format(formats.DATE) }}

          dl(class='sm:grid-cols-3 grid grid-cols-1 gap-x-4 gap-y-8')
            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Households
              dd.mt-1.text-sm.text-gray-900 {{ expense.numOfHouseholds || 'Not set' }}

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Impressions
              dd.mt-1.text-sm.text-gray-900 {{ expense.numOfImpressions || 'Not set' }}

            div(class='sm:col-span-1')
              dt.text-sm.font-medium.text-gray-500 Pieces
              dd.mt-1.text-sm.text-gray-900 {{ expense.numOfPieces || 'Not set' }}

          h5.mt-8 Attachments
          v-divider.mb-5

          div( v-if="!isPending" )
            .text-xs( v-if="expense.attachments && expense.attachments.length" ).text-gray-400
              | {{ expense.attachments.length }} files
            .divide-y.divide-dashed
              .d-flex.justify-between.align-baseline.my-1(
                v-for="attachment in expense.attachments"
                :key="attachment.id"
              )
                .text-sm.py-2.pr-4
                  span( style="word-break: break-all;" ) {{ attachment.name }}
                  span.inline-block.ml-2.text-xs.text-gray-400 ({{ attachment.size }}) - {{ $day(attachment.createdAt).format(formats.DATE) }}
                v-btn.ml-1( color="white" x-small fab depressed )
                  a( :href="attachment.url" :download="attachment.name" )
                    v-icon mdi-download

              div( v-if="expense.attachments && expense.attachments.length === 0" )
                .my-8.text-gray-500.text-center.w-full.text-sm No Attachments


          ExpenseActions.mt-16(
            context="view"
            v-if="!isPending"
            :expense="expense"
            @submit-action="refreshExpense()"
          )

        v-tab-item.pa-8.border-t( v-if="!isExpenseHistoriesPending" )
          ExpenseHistoryTimeline( :expenseHistories="expenseHistories" )
</template>

<script>
import useApiGet from '@/api/useApiGet'
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import ExpenseChip from './Expense.Chip.vue';
import { formats } from '@/helpers/data'
import ExpenseActions from './Expense.Actions.vue'
import ExpenseHistoryTimeline from './ExpenseHistory.Timeline.vue'

export default {
  name: 'ExpenseView',

  components: {
    ExpenseChip,
    ExpenseActions,
    ExpenseHistoryTimeline
  },

  setup(props, { root: { $route, $FeathersVuex, $store }}) {
    const { Expense, ExpenseHistories } = $FeathersVuex.api
    const id = parseInt($route.params.id);
    const { group } = $store.getters['auth/user'];

    const eagers = group === 'organization'
      ? '[orgType, vendor, advocacy, candidate, attachments, history(latest), campaign]'
      : '[vendor, advocacy, candidate, attachments, history(latest)]'
    const getParams = {
      query: {
        $eager: eagers
      }
    }
    const { item: expense, isPending, get: getExpense } = useApiGet({
      model: Expense,
      id,
      params: getParams
    })

    const breadcrumbs = computed(() => {
      return [{
        text: 'Expenses',
        link: true,
        disabled: false,
        exact: true,
        to: '/expenses'
      },
      {
        text: expense.value?.invoiceNumber || 'Incomplete Invoice',
        disabled: true
      }]
    })

    const refreshExpense = () => getExpense(id.value, getParams)

    const historyParams = computed(() => {
      return {
        query: {
          $eager: '[user]',
          expenseId: parseInt(id),
          $sort: { createdAt: -1 },
          $limit: 25
        }
      }
    })
    const {
      items: expenseHistories,
      isPending: isExpenseHistoriesPending,
      find: findHistories
    } = useFind({
      model: ExpenseHistories,
      params: historyParams
    })

    const tab = ref('')

    return {
      expense,
      isPending,

      expenseHistories,
      isExpenseHistoriesPending,
      getExpense,
      getParams,

      findHistories,
      refreshExpense,

      tab,
      breadcrumbs,
      formats
    }
  }
}
</script>

<style>
</style>